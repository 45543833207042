import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import Header from "./header";

function Layout({ children }) {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
          
          allMarkdownRemark(sort: {fields: frontmatter___shortTitle}) {
              edges {
                  node {
                      id
                      fields {
                          slug
                      }
                      frontmatter {
                          shortTitle
                          title
                      }
                  }
              }
          }
      }
  `);

  return (
    <div className="flex flex-col font-sans min-h-screen text-gray-900">
      <Header siteTitle={data.site.siteMetadata.title} pages={data.allMarkdownRemark.edges}/>

      <main className="flex flex-col flex-1 md:justify-start max-w-4xl mx-auto px-3 py-5 md:p-8 w-full">
        {children}
      </main>

      <footer className="bg-blue-700">
        <nav className="flex justify-center max-w-4xl mx-auto p-4 md:p-8 text-sm">
          <p className="text-white">
            <strong>UARSHelp</strong> © 2020
          </p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
